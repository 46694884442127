import React from 'react'
import { Image } from 'pcln-design-system'
import BrandLogo from './index'

const sizeProps = {
  height: '56px',
  width: '94px',
  objectFit: 'contain'
} as const
const hotels = [
  {
    url: 'https://s1.pclncdn.com/design-assets/hotel/brand-logos/best_western.svg',
    alt: 'Best Western'
  },
  {
    url: 'https://s1.pclncdn.com/design-assets/hotel/brand-logos/sheraton.svg',
    alt: 'Sheraton'
  },
  {
    url: 'https://s1.pclncdn.com/design-assets/hotel/brand-logos/holiday_inn.svg',
    alt: 'Holiday Inn'
  },
  {
    url: 'https://s1.pclncdn.com/design-assets/hotel/brand-logos/marriott.svg',
    alt: 'Marriott'
  },
  {
    url: 'https://s1.pclncdn.com/design-assets/hotel/brand-logos/extended_stay.svg',
    alt: 'Extended Stay'
  },
  {
    url: 'https://s1.pclncdn.com/design-assets/hotel/brand-logos/westin.svg',
    alt: 'Westin'
  },
  {
    url: 'https://s1.pclncdn.com/design-assets/hotel/brand-logos/la_quinta.svg',
    alt: 'La Quinta'
  },
  {
    url: 'https://s1.pclncdn.com/design-assets/hotel/brand-logos/hyatt.svg',
    alt: 'Hyatt'
  },
  {
    url: 'https://s1.pclncdn.com/design-assets/hotel/brand-logos/quality_inn.svg',
    alt: 'Quality Inn'
  }
] as const

function HotelsBrandLogo() {
  return (
    <BrandLogo tagLine="COMPARE PRICES FROM THE TOP HOTEL BRANDS YOU TRUST">
      {hotels.map(src => (
        <Image
          loading="lazy"
          ml={3}
          mr="12px"
          key={src.url}
          src={src.url}
          alt={src.alt}
          {...sizeProps}
        />
      ))}
    </BrandLogo>
  )
}

export default HotelsBrandLogo
