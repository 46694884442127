import React from 'react'
import { Box, Flex, Text } from 'pcln-design-system'

function BrandLogo({
  children,
  tagLine
}: {
  children: React.ReactElement[]
  tagLine: string
}) {
  const logos = React.Children.map(children, child => (
    <Box mx={2} my={3} textAlign="center" width="98px">
      {child}
    </Box>
  ))

  return (
    <Box>
      <Text fontSize={2} fontWeight="bold" mx={2} mb={2} textAlign="center">
        {tagLine}
      </Text>
      <Flex flexWrap="wrap" justifyContent="center">
        {logos}
      </Flex>
    </Box>
  )
}

export default BrandLogo
