import React from 'react'
import MobileHeader from '@pcln/mobile-header'
import { Box } from 'pcln-design-system'
import {
  ANALYTICS_CATEGORY_FLIGHTS,
  ANALYTICS_CATEGORY_PACKAGES
} from '@/constants/analytics'
import useBootstrapData from '@/hooks/useBootstrapData'

type AnalyticsCategory =
  | typeof ANALYTICS_CATEGORY_PACKAGES
  | typeof ANALYTICS_CATEGORY_FLIGHTS
const ANALYTICS_CATEGORY: Record<string, AnalyticsCategory> = {
  flights: ANALYTICS_CATEGORY_FLIGHTS,
  packages: ANALYTICS_CATEGORY_PACKAGES
}

function MobileHeaderWrapper({
  selectedProductName,
  children
}: {
  selectedProductName: string
  children: React.ReactNode
}) {
  const { key } = useBootstrapData()
  const category = ANALYTICS_CATEGORY[key]

  return (
    <Box
      mb={4}
      mt={2}
      boxShadowSize="xl"
      borderRadius="2xl"
      bg="background.lightest"
    >
      <Box mb="10px">
        <MobileHeader
          analyticsCategory={category}
          items={MobileHeader.items}
          selectedItem={selectedProductName}
        />
      </Box>
      {children}
    </Box>
  )
}

export default MobileHeaderWrapper
