import React, { useState } from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'pcln-design-system'
import StyledHeading from '@/components/StyledHeading'
import MobileHeaderWrapper from '@/components/MobileHeaderWrapper'
import HotelsBrandLogo from '@/components/BrandLogo/HotelsBrandLogo'
import HotelsTonight from '@/components/HotelsTonight/HotelsTonight'
import useContentfulSearchFormHeadlines from '@/hooks/useContentfulSearchFormHeadlines'
import type { LOCATION_SEARCH_TYPE } from '@/types'
import SearchFormProvider from '@/context/SearchFormContext'
import IncredibleTile from '@/components/Promos/IncredibleTile'
import TripActivity from '@/components/TripActivity/TripActivity'
import { ANALYTICS_CATEGORY_HOTELS } from '@/constants/analytics'
import HeroImageParallax from '@/components/Heros/HeroImageParallax'
import useExpressDealDialog from '@/hooks/useExpressDealDialog'
import useBootstrapData from '@/hooks/useBootstrapData'
import HeroMobile from './Hero/HeroMobile'
import { handleSubmitForHotels } from './SearchForm/utils'
import Form from './SearchForm/Form'
import UrgencyMessage from './UrgencyMessage'
import FormHeadline from './FormHeadline'
import { HotelsBumper } from '../SearchForm/Bumpers'
import ExpressDealDialog from './ExpressDeal/ExpressDealDialog'
import LayoutComponentWrapper from '../LayoutComponentWrapper'
import LinksWidget from '../SkyMondeSeo/LinksWidget'

const StyledBox = styled(Box)`
  overflow: hidden;
  min-height: 330px;
`
export default function HotelsMobile() {
  const { isOpen, closeDialog } = useExpressDealDialog()
  const [location, setLocation] = useState<LOCATION_SEARCH_TYPE | undefined>()
  const { skymondeSEO } = useBootstrapData()
  const searchForm = useContentfulSearchFormHeadlines('searchForm')
  const subheadline = searchForm?.mobileSubheadline ?? ''

  return (
    <Box data-testid="hotels-mobile">
      <ExpressDealDialog
        isMobile
        gaCategory={ANALYTICS_CATEGORY_HOTELS}
        isOpen={isOpen}
        closeDialog={closeDialog}
      />
      <MobileHeaderWrapper selectedProductName="Hotels">
        <HeroMobile />
        <SearchFormProvider>
          <Box px={3}>
            <Flex flexDirection="column">
              <Box mr="auto" mb={2}>
                <UrgencyMessage location={location} />
              </Box>
              <FormHeadline location={location} />
              <StyledHeading
                text={subheadline}
                color="text.light"
                mt={1}
                fontSize={1}
                tag="h2"
              />
            </Flex>
            <Box my={3}>
              <Form
                onSubmit={handleSubmitForHotels}
                setLocation={setLocation}
              />
            </Box>
          </Box>
          <HotelsBumper />
        </SearchFormProvider>
      </MobileHeaderWrapper>
      <StyledBox mt={-48} rounded="bottom" borderRadius="2xl">
        <HeroImageParallax
          isScrollable
          gptId="div-gpt-ad-1509978154282-0"
          slot="/3102/priceline.dart/hotel_homepage_hero_image"
        >
          <Box textAlign="center" width={1} px={3} pt={48} pb={4}>
            <div id="div-gpt-ad-1510170073577-0" />
          </Box>
        </HeroImageParallax>
      </StyledBox>
      <Box mx={3} my={4}>
        <TripActivity
          productType={['STAY']}
          analyticsCategory={ANALYTICS_CATEGORY_HOTELS}
        />
      </Box>
      <LayoutComponentWrapper>
        <Flex mx={3}>
          <IncredibleTile contentFulFieldID="incredibleTileMediaCard" />
        </Flex>
        <Box mx={3}>
          <HotelsTonight gaCategory={ANALYTICS_CATEGORY_HOTELS} />
        </Box>
        <HotelsBrandLogo />
        <Box id="div-gpt-ad-1541166805838-0" textAlign="center" mb={4} />
        {skymondeSEO ? <LinksWidget isMobile data={skymondeSEO} /> : null}
      </LayoutComponentWrapper>
    </Box>
  )
}
