import React, { type SyntheticEvent } from 'react'
import { defaultToday, defaultTomorrow } from '@/shared-utils/date-helper'
import analytics from '@/shared-utils/analytics'
import useGeoPosition from '@/hooks/useGeoPosition'
import { ActionCard } from '@pcln/cards'
import { ANALYTICS_CATEGORY_HOME } from '@/constants/analytics'
import { invokeFetchCurrentCityData } from '../../machines/currentLocationMachine'

export default function HotelsTonight({
  gaCategory = ANALYTICS_CATEGORY_HOME
}: {
  gaCategory?: string
}) {
  const { latitude, longitude } = useGeoPosition()
  const relaxListingsCallBack = async () => {
    if (latitude !== null && longitude !== null) {
      try {
        const { lat, lon, itemName } = await invokeFetchCurrentCityData(
          latitude,
          longitude
        )
        window.location.href = `/relax/geo/${itemName}/${lat}/${lon}/from/${defaultToday}/to/${defaultTomorrow}/rooms/1`
      } catch (error) {
        analytics.fireEvent({
          event: 'Error',
          category: gaCategory,
          action: 'Click',
          data: (error as Error).toString()
        })
        window.location.href = '/hotels'
      }
    } else {
      analytics.fireEvent({
        event: 'Error',
        category: gaCategory,
        action: 'Click',
        data: 'Latitude/Longitude data could not be fetched'
      })
    }
  }

  const handleHotelsTonightClick = () => {
    analytics.fireEvent({
      category: gaCategory,
      action: 'Click',
      label: 'Hotel Tonight'
    })
    void relaxListingsCallBack()
  }

  return (
    <ActionCard
      heading="Need a hotel tonight?"
      caption="Find deals near you."
      imageUrl="https://s1.pclncdn.com/design-assets/next-landing/assets/images/promos/late-night.jpg?opto&auto=avif,webp"
      imageAlt="Hotels Tonight"
      onClick={(event: SyntheticEvent) => {
        event.preventDefault()
        handleHotelsTonightClick()
      }}
      handleSubmitCTA={(event: Event) => {
        event.preventDefault()
        handleHotelsTonightClick()
      }}
    />
  )
}
